import axios from '@/plugins/axios'

/**
 * Request campaign list report
 */
const requestCampaignListReport = async ({
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date
}) => {
  try {
    const response = await axios.post('admin/reports/campaign-list', {
      start_date: start_date,
      end_date: end_date
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Request commission payable
 */
const requestCommissionReport = async ({
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date
}) => {
  try {
    const response = await axios.post('admin/reports/commission', {
      start_date: start_date,
      end_date: end_date
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Request financial report
 */
const requestFinancialReport = async ({
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date,
  grouping
}) => {
  try {
    const response = await axios.post('admin/reports/financial', {
      start_date: start_date,
      end_date: end_date,
      grouping: grouping
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Request revenue report
 */
const requestRevenueReport = async ({
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date
}) => {
  try {
    const response = await axios.post('admin/reports/revenue', {
      start_date: start_date,
      end_date: end_date
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Request spending report
 */
const requestSpendingReport = async ({
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date
}) => {
  try {
    const response = await axios.post('admin/reports/spending', {
      start_date: start_date,
      end_date: end_date
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Request test status report
 */
const requestTestStatusReport = async () => {
  try {
    const response = await axios.post('admin/reports/test-status')
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Request financial report
 */
const requestUsersReport = async ({
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date,
  orders,
  campaigns,
  historic
}) => {
  try {
    const response = await axios.post('admin/reports/users', {
      start_date: start_date,
      end_date: end_date,
      orders: orders,
      campaigns: campaigns,
      historic: historic
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  requestCampaignListReport,
  requestCommissionReport,
  requestFinancialReport,
  requestRevenueReport,
  requestSpendingReport,
  requestTestStatusReport,
  requestUsersReport
}
